<template>
  <div class="main-content">
    <div class="page-head-tile">
      <div class="container">
        <div class="breadcrumbs-block">
          <ul class="breadcrumbs">
            <li class="bc-item">
              <router-link :to="{name: 'index'}">Главная</router-link>
              <i class="fa fa-angle-right"></i>
            </li>
            <li class="bc-item">
              <router-link :to="{name: 'documents'}">Документы</router-link>
              <i class="fa fa-angle-right"></i>
            </li>
            <li class="bc-item">
              <router-link :to="{ name: 'open_category', params: {catid: $store.state.category.id}}">{{ $store.state.category.title }}</router-link>
            </li>
          </ul>
        </div>
        <div class="page-title">
          <h1 class="title-16"><strong>{{ $store.state.category.title }}</strong></h1>
        </div>
      </div>
    </div>
    <div class="section post-section">
      <div class="row">
        <div class="full">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div class="post-main-img">
                    <div class="post-main-overlay">
                      <div class="post-main-data"></div>
                    </div>
                  </div>
                  <h3>{{document.title || ''}}</h3>
                  <hr>
                  <div v-html="document.content" v-if="document.content"></div>
                  <hr>
                  <p v-for="(file, index) in document.get_files" :key="index">
                    <a :href="file.url" target="_blank">{{file.name}}</a>
                  </p>
                  <div class="all-sb">
                    <p>{{document.updated_at | human_date}}</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project',
  async asyncData (context) {
    await context.store.dispatch('documentsListPage');
    await context.store.commit('document', context.route.params);
  },
  metaInfo () {
    return this.$seo(this.$store.state.document.title || 'Документ')
  },
  data () {
    return {
      document: this.$store.state.document
    };
  },
};
</script>
